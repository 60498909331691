<template>
  <div class="rsvp-form">
    <form v-if="!status">
      <div class="field">
        <label class="label" for="email">Email:</label>
        <div class="control">
          <input class="input" type="email" id="email" name="email" v-model="email">
          <span v-if="emailError" class="has-text-danger">{{ emailError }}</span>
        </div>
      </div>
      <div class="field">
        <label class="label" for="willBeThere">Will you be there?</label>
        <div class="control">
          <div class="select">
            <select id="willBeThere" name="willBeThere" v-model="willBeThere">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label" for="foodRequirements">Do you have any food allergies or requirements?</label>
        <div class="control">
          <input class="input" type="text" id="foodRequirements" name="foodRequirements" v-model="foodRequirements">
        </div>
      </div>
      <div class="field">
        <label class="label" for="photos">Text me photos of Davis 339-223-0663</label>
      </div>
      <div class="field">
        <div class="control">
          <button class="button is-primary" type="button" @click="submitRSVP">RSVP</button>
        </div>
      </div>
    </form>

    <div v-if="status" class="rsvp-sent">
      <div class="is-large title status-text">{{ status }}</div>
      <div>
        <button class="button" @click="status = ''">Edit RSVP</button>
      </div>
    </div>

  </div>
</template>

<script>
import { getDatabase, ref as dbRef, set } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, push } from 'firebase/storage';


export default {
  name: 'RSVPForm',
  props: {
  },
  data: function () {
    return {
      status: '',
      email: '',
      emailError: '',
      willBeThere: "Yes",
      foodRequirements: "",
      photos: []
    };
  },
  methods: {
    handlePhotoChange: function (event) {
      this.photphotosoFiles = event.target.files;
    },
    submitRSVP: function () {

      // Make sure email field is not empty and it is a valid email address
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        // Display error message and return without submitting the form
        this.emailError = 'Please enter a valid email address.';
        return;
      }

      const email = this.email;
      const timestamp = new Date().toISOString();
      const willAttend = this.willBeThere
      const foodRequirements = this.foodRequirements !== undefined ? this.foodRequirements : 'no';

      // Reference to Firebase Realtime Database
      const database = getDatabase();
      // Add RSVP data to Firebase using email as the key
      const email_key = email.replace(/\./g, '-').replace(/#/g, '_').replace(/\$/g, '+');

      set(dbRef(database, 'rsvps/' + email_key), {
        email,
        timestamp,
        willAttend,
        foodRequirements
      });

      // Show success message after data is submitted
      this.status = 'Thanks!';

      // Clear form data
      this.email = '';
      this.willAttend = undefined;
      this.willCamp = undefined;
      this.foodRequirements = undefined;
      this.photos = undefined;
    }

  }
}
</script>

<style scoped lang="scss">
.rsvp-form {
  background: rgba(255, 255, 255, 1);
  padding: 30px;
  max-width: 100%;
  min-height: 100vh;

  form {
    max-width: 400px;
  }
}

.status-text {
  padding: 30px;
}

.rsvp-sent {
  width: 100%;
  text-align: center;
}
</style>
