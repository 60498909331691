import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import './main.scss'

// Initialize Firebase
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB_6D4yVGgMD2HF3vGAcV2SIIDwHoi01sE",
    authDomain: "davis-2023.firebaseapp.com",
    projectId: "davis-2023",
    storageBucket: "davis-2023.appspot.com",
    messagingSenderId: "728547240498",
    appId: "1:728547240498:web:348d359f97ac0757b7ea2b"
};
initializeApp(firebaseConfig);

createApp(App).use(store).use(router).mount('#app')
