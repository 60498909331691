<template>
    <div class="wrapper" id="home">
        <!-- <div class="details">
            <h1> Davis is getting married!</h1>
            <div class="rsvp-cta">
                <button @click="showRSVP" class="button is-large">RSVP</button>
            </div>
            <small class="footer-text">#davis2023</small>
        </div> -->
    </div>
    <!-- <rsvp-form id="rsvp" /> -->
</template>
<script>
import RSVPForm from '../components/rsvp-form.vue'
export default {
    name: 'App',
    components: {
        'rsvp-form': RSVPForm
    },
    data: () => {
        return {
        }
    },
    methods: {
        showRSVP() {
            const anchor = document.querySelector('#rsvp');
            anchor.scrollIntoView({ behavior: 'smooth' });
        }
    }

}
</script>
<style lang="scss">
// scale the canvas down 3x
canvas {
    transform: scale(0.33);
    transform-origin: top left;
    width: 300%;
    height: 300%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.rsvp {
    width: 100%;
    min-height: 100vh;
    z-index: 100;
    position: absolute;

    .wrapper {
        // border: 10px solid #ffffff;
        margin: 10px;
        width: calc(100vw - 20px);
        height: calc(100vh - 20px);
    }

    .details {
        min-height: calc(100vh - 60px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h1 {
            color: white;
            margin: 30px;
            font-size: 14vw;
            text-transform: uppercase;
            text-decoration: underline;
            max-width: 50%;
        }

        @media screen and (min-width: 1000px) {
            h1 {
                font-size: 9vw;

            }
        }


        .rsvp-cta {
            width: 100%;
            text-align: center;
        }

        small {
            color: white;
            padding-top: 10px;
            display: flex;
        }

        .footer-text {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            margin-bottom: 10px;

            small {
                display: flex;
            }

        }
    }
}
</style>
  