<template>
    <div>
        <ul>
            <li v-for="guest in this.rsvp">
                <div class="is-size-5">{{ guest.email }}</div>
                <div class="is-size-7">Will willAttend: <b
                        :class="{ 'has-text-success': guest.willAttend === 'yes' || guest.willAttend === 'Yes' }">{{
                            guest.willAttend }}</b></div>
                <div class="is-size-7">foodRequirements: <small>{{ guest.foodRequirements }}</small></div>
            </li>
        </ul>
    </div>
</template>

<script>
import { getDatabase, ref, get } from 'firebase/database';

export default {
    name: 'GuestList',
    props: {
    },
    data() {
        return {
            rsvp: {}
        };
    },
    methods: {
    },
    mounted() {
        const database = getDatabase();
        const dataRef = ref(database, 'rsvps/');

        // Retrieve data
        get(dataRef)
            .then((snapshot) => {
                // Access the data in the snapshot object
                const data = snapshot.val();
                this.rsvp = data
            })
            .catch((error) => {
                console.error(error);
            });
    },
    destroyed() {
    }
}
</script>

<style lang="scss" scoped>
ul {
    background: white;
    padding: 30px;
    max-width: 50vw;
    margin: 50px auto;


    li {
        padding: 30px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #eee;

        div {
            padding-bottom: 10px;
        }
    }
}
</style>